.slide-enter-active[data-v-16f72370] {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}
.slide-leave-active[data-v-16f72370] {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to[data-v-16f72370], .slide-leave[data-v-16f72370] {
  max-height: 250px;
  overflow: hidden;
}
.slide-enter[data-v-16f72370], .slide-leave-to[data-v-16f72370] {
  overflow: hidden;
  max-height: 0;
}
